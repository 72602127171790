import React from 'react';
import {useState,useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Toast, InputGroup, Form, Button, Badge} from 'react-bootstrap';
import {getMyToken, onMessageListener} from './firebase'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
} from "mdb-react-ui-kit";

function App() {

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: '',time:'', id:''});
  const [isTokenFound, setTokenFound] = useState(false);
  const [currToken, setToken] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const [notifications, SetNotifications] = useState([]);
  useEffect(() => {
    if(Notification.permission === "granted"){
      getMyToken(setTokenFound,setToken);
    }

  });
  function ClickMe() {

    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
      
    }
    else if (Notification.permission !== "denied") {
      getMyToken(setTokenFound,setToken);

    }
  }

  
  onMessageListener().then(payload => {
    setShow(true);
    setShowChat(true);
    let currTime = new Date().toLocaleTimeString();
    setNotification({title: payload.notification.title, body: payload.notification.body, time:currTime, id:payload.messageId})
    SetNotifications([
      ...notifications,
      { title: payload.notification.title, body: payload.notification.body, time:currTime, id:payload.messageId}
    ]);
 
  }).catch(err => console.log('failed: ', err));
  return (

    <MDBContainer fluid className="py-5" style={{ backgroundColor: "#FAF9F6",height:'100vh' }}>
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="10" lg="8" xl="6">
        <MDBCard id="chat2" style={{ borderRadius: "15px" }}>
          <MDBCardHeader className="d-flex justify-content-between align-items-center p-3">
            <h5 className="mb-0">Chat</h5>
            {!isTokenFound ? <Button className='primary' onClick={ClickMe} >Allow Notifications</Button>:<Badge className='bage-back' bg="bage-back">Notifications Allowed</Badge>}
          
          </MDBCardHeader>
        
            <MDBCardBody >
              { showChat ?
              <div>
                {notifications.map(noti =>(
                  <div key={noti.id} className="d-flex flex-row justify-content-start">
                  <img
                    src="https://www.reflex.co.za/media/t1sgtynd/logo-reflex-blue-portrait.png"
                    alt="avatar 1"
                    style={{ width: "45px", height: "100%" }}
                  />
                  <div>
                    <p
                      className="small p-2 ms-3 mb-1 rounded-3"
                      style={{ backgroundColor: "#f5f6f7" }}
                    >
                    {noti.title}
                    </p>
                    <p
                      className="small p-2 ms-3 mb-1 rounded-3"
                      style={{ backgroundColor: "#f5f6f7" }}
                    >
                     {noti.body}
                    </p>
                    <p className="small ms-3 mb-3 rounded-3 text-muted">
                      {noti.time}
                    </p>
                  </div>
                </div> 
                ))}
             
              </div>
            :<div>
               <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3"
                    style={{ backgroundColor: "#f5f6f7" }}
                  >
                 No notifications
                  </p>
                  
                  </div>
              </div>
              }
            </MDBCardBody>
   
          <MDBCardFooter className="text-muted d-flex justify-content-start align-items-center p-3" >
            <img
              src="https://www.reflex.co.za/media/t1sgtynd/logo-reflex-blue-portrait.png"
              alt="avatar 3"
              style={{ width: "45px", height: "100%" }}
            />
            <input
              type="text"
              className="form-control form-control-lg"
              id="exampleFormControlInput1"
              placeholder="Type message"
            ></input>
           
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    <Toast 
      onClose={() => setShow(false)}
      show={show} delay={10000} autohide animation
      style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">{notification.title}</strong>
        <small>{notification.time}</small>
      </Toast.Header>
      <Toast.Body>{notification.body}</Toast.Body>
    </Toast>
    <div className='App pt-4'>
    {isTokenFound ?
        <a href='#' onClick={()=>{setShowCode(!showCode)}}>{showCode?'Hide Code':'Show Code'}</a>
        :<></>}
        {showCode ?<InputGroup className='pt-4'>
         <InputGroup.Text onClick={() => {navigator.clipboard.writeText(currToken)}}>Copy</InputGroup.Text>
        <Form.Control as="textarea" aria-label="With textarea" defaultValue={currToken} />
      </InputGroup>:<></>}
         
      </div>
  </MDBContainer>
  

  );
}

export default App;
