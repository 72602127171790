import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCkmbhzLhug2IRViwTTQbq0PlVukgr84Ow",
  authDomain: "reflex-push-cf51b.firebaseapp.com",
  projectId: "reflex-push-cf51b",
  storageBucket: "reflex-push-cf51b.appspot.com",
  messagingSenderId: "697391082980",
  appId: "1:697391082980:web:af3cc1f763bb2d7bf3abf1"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

initializeApp(firebaseConfig);

export const getMyToken = (setTokenFound,setToken) => {
    return getToken(messaging, {vapidKey: 'BMB3Hv5KIhdN64BT95LWrY6ErQuFDMnECL8CFEbnHt5rnOu3tR9l-ihqaGe_OTxrwBPkuTwF4ukeHAL0klSHxIo'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        setToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }

  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });